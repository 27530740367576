import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                <span>| </span>
                <a href="/nordic-berry/" className="link">
                  Nordic Berry
                </a>
                <span />
                <span>|</span>
                <a href="/plet-s-nedokonalostmi/">Pleť s nedokonalostmi</a>
                <span />
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/45/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> Norská receptura Intenzivní
                    regenerační tělové mléko na citlivou pokožku
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NEUTROGENA-intenzivni-regeneracni-telove-mleko-sensitive-red.jpg"
                      alt="NEUTROGENA intenzivni regeneracni telove mleko sensitive red"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup> Norská receptura Intenzivní
                      regenerační tělové mléko na citlivou pokožku
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        Intenzivní regenerační tělové mléko bez parfemace se
                        speciálním složením vytvořeným pro minimalizaci rizik
                        vzniku alergií. Působí jako ošetřující „zábal“*, který
                        pomáhá regenerovat velmi suchou a podrážděnou pokožku
                        již po 1 použití. Pocítíte okamžitou úlevu a zklidnění
                        po prvním použití. Krémová, nemastná textura se snadno
                        vstřebává.
                        <br />
                        *efekt kosmetického zábalu
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Nanášejte jednou či dvakrát denně na celé tělo, především
                      na suché partie (jako kolena, lokty, nohy).{" "}
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>250 ml; 400 ml</dd>
                  </dl>
                </div>
                <div id="fb_9"></div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobrazit související produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/48/"
                  >
                    <img
                      src="/assets/Uploads/NTG-HandCream-50ml-parfemovany.png"
                      alt="NTG HandCream 50ml parfemovany"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Krém na ruce
                        parfémovaný
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/49/"
                  >
                    <img
                      src="/assets/Uploads/NTG-HandCream-50ml-unscented.png"
                      alt="NTG HandCream 50ml unscented"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Krém na ruce
                        neparfémovaný
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/54/"
                  >
                    <img
                      src="/assets/Uploads/NTG-Krem-na-rozpraskane-paty-40ml-box.png"
                      alt="NTG Krem na rozpraskane paty 40ml box"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Krém na
                        rozpraskané paty
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/55/"
                  >
                    <img
                      src="/assets/Uploads/NTG-Lipp-LSF4-48g-bezblistru2.png"
                      alt="NTG Lipp LSF4 48g bezblistru2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Balzám na rty
                        SPF 4
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/56/"
                  >
                    <img
                      src="/assets/Uploads/Nutrogena-LSF-20-SUN.png"
                      alt="Nutrogena LSF 20 SUN"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Balzám na rty
                        SPF 20
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
